<template>
    <div class="CEESAR-Footer">
        <div class="CEESAR-Footer-Left">
            <slot name = "Left"/>
        </div>
        <div class="CEESAR-Footer-Middle">
            <slot name = "Middle"/>
        </div>
        <div class="CEESAR-Footer-Right">
            <slot name = "Right"/>
        </div>
    </div>
</template>

<script>
export default {
        name: "CEESAR-Footer"
}
</script>

<style>
</style>